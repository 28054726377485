import React, { Component } from 'react'

export class Spinner extends Component {
  render() {
    return (

      <div>
        <div className="d-flex align-items-center">
          <div className="row w-100 mx-0">
            <div className="spinner-wrapper">
              <div className="donut"></div>
            </div>
          </div>
        </div>
      </div>



    )
  }
}

export default Spinner
