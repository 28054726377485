import React from 'react';
import {AsyncStorage} from 'AsyncStorage'
export const KEY_ADMIN = "ADMIN";
export const KEY_PARTNER = "PARTNER";
export const KEY_ORGANISER = "ORGANISER";




class Session extends React.Component {
  
  async loginAdmin(admin) {

    window.sessionStorage.setItem(KEY_ADMIN,admin);

  }
  async loginPartner(partner) {

    window.sessionStorage.setItem(KEY_PARTNER,partner);

  }

  async loginOrganiser(organiser) {

    window.sessionStorage.setItem(KEY_ORGANISER,organiser);

  }
  

  async logoutAdmin() {
    window.sessionStorage.removeItem(KEY_ADMIN);
  }
  async logoutPartner() {
    window.sessionStorage.removeItem(KEY_PARTNER);
  }
  async logoutOrganiser() {
    window.sessionStorage.removeItem(KEY_ORGANISER);
  }
}
const session = new Session();
export default session;
